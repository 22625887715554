.split-button-main {
    pointer-events: none;
    display: none;
    opacity: 0.5;
}

.p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover,
.p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover * {
    color: #1F78FF !important;
    background-color: #EBF2FF;
}

.p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content,
.p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content * {
    color: #1F78FF !important;
    background-color: #EBF2FF;
}

.p-tieredmenu.p-tieredmenu-overlay {
    background: #EBF2FF;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
}

.p-menubar-button {
    display: none;
}