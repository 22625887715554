.least .p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: rgba(227, 80, 107, 0.2);
    border-radius: 6px;
  }

.most .p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: rgba(36, 214, 165, 0.2);
    border-radius: 6px;
  }


  .home .p-tabview .p-tabview-nav {
    justify-content: center;
  }
  
