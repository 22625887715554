.grid-item:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

.grid-item.selected {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.input-number {
    background-color: #e9f1fa;
    border-radius: 20px;
}

.p-button {
    transition: none;
    box-shadow: none;
}

.input-text-appointment {
    background-color: #e9f1fa;
    border-radius: 20px;
    width: 25rem;
}

.description-text-area {
    background-color: #e9f1fa;
}

.exercises-image > img {
    border-radius: 1.5rem;
}