.feedback-support-dataview .p-dataview-header {
    background-color: #FFFFFF;
    border: none;
    padding: 0;
}

.truncate-multi-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: 3em;
    line-clamp: 2;
}