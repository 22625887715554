.userManagmentTable .p-datatable .p-datatable-header {
    background: white;
    color: #374151;
    border: none;
}

.userManagmentTable .p-datatable .p-datatable-thead>tr>th {
    background: white;
}

.userManagmentInput .p-inputtext {
    border-radius: 1rem;
}

.userManagmentDropdown .p-dropdown {
    border-radius: 1rem;
}

.userManagmentButton .p-selectbutton .p-button.p-highlight {
    background: #111F52;
    border-color: #111F52;
}

.userManagmentPassword .p-icon-field {
    width: 100%
}

.userManagmentPassword .p-icon-field-right>.p-inputtext {
    width: 100%
}