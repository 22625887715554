.cardForClinicList .p-card .p-card-content {
  padding: 0;
}

.clickableDiv:hover {
  cursor: pointer;
  transform: scale(1.001);
  transition: transform 0.3s ease;
}

.clickableImg:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.reportTable .p-datatable .p-datatable-header {
  background: white;
  color: #374151;
  border: none;
}

.reportTable .p-datatable .p-datatable-thead>tr>th {
  background: white;
}